
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {useCustomMutation} from "../../useCustomMutation";

export interface UserData {
    name : string;
    surename : string;
    phone : string;
    password: string;
    roles?: [string]
}

export const useCreateUser = () => {
    const {t} = useTranslation();
    return useCustomMutation<UserData, UserData>('/user', {

        onError: (error:any) => {
            toast.error(t(error?.message || 'Error'));
            console.error('Registration failed', error);
        },
    });
};

