import {useLocation, useNavigate} from "react-router-dom";
import Button from "../../../components/common/Button";
import PageInfo from "../../../components/dashboard/PageInfo";
import DataTable from "../../../components/dashboard/DataTable";
import FlexWrapper from "../../../components/common/FlexWrapper";
import { PlusIcon, PenIcon, TrashIcon } from "../../../utils/icons";
import { Column } from "../../../types/table.types";
import './styles.sass';
import useCompanies from "../../../api/hooks/company/useCompanies";
import { useTranslation } from "react-i18next";
import {getUserIdFromToken} from "../../../utils/authUtils";
import {useEffect} from "react";
import queryClient from "../../../config/react-query/queryClient";

interface CompaniesRow {
  name: string;
  director: string;
  tin: string;
  employeeCount: string;
  transportCount: string;
  founded: string;
}

const CompaniesTable = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const userId:any = getUserIdFromToken()
  console.log(userId)

  const { data: companies, isLoading, error, refetch } = useCompanies(userId);

  useEffect(() => {
    refetch();
  }, [location]);

  const columns: Column[] = [
    {
      id: 'companiesName',
      headerName: t('companyName'),
      flex: 1,
      renderCell(row) {
        return (
            <div
                onClick={() => navigate(`view/${row.id}/info`)}
                style={{cursor: "pointer", textDecoration: "underline", color: "#3B7CCC"}}
            >
              {row.name}
            </div>
        );
      },
    },
    {
      id: 'director',
      headerName: t('companyDirector'),
      flex: 2,
      renderCell(row) {
        return <div>{row.owner?.name}</div>;
      },
    },
    {
      id: 'tin',
      headerName: t('tin'),
      flex: 1,
      renderCell(row) {
        return <div>{row.inn}</div>;
      },
    },
    {
      id: 'driver-count',
      headerName: t('driverCount'),
      flex: 1,
      renderCell(row: CompaniesRow) {
        return <div>{row?.employeeCount}</div>;
      },
    },
    {
      id: 'transport-count',
      headerName: t('transportCount'),
      flex: 1,
      renderCell(row: CompaniesRow) {
        return <div>{row.transportCount}</div>;
      },
    },
    {
      id: 'created-year',
      headerName: t('yearFounded'),
      flex: 1,
      renderCell(row: CompaniesRow) {
        return <div>{row?.founded}</div>;
      },
    },
    {
      id: 'action',
      headerName: '',
      flex: 0.5,
      renderCell(row: CompaniesRow) {
        return (
            <FlexWrapper>
              <Button variant="clear">
                <PenIcon />
              </Button>
              <Button variant="clear">
                <TrashIcon />
              </Button>
            </FlexWrapper>
        );
      },
    },
  ];

  if (isLoading) {
    return <div className="page-companies-table">{t('loading')}</div>;
  }

  if (error) {
    return <div className="page-companies-table">{t('errorLoadingCompanies')}</div>;
  }

  return (
      <div className="page-companies-table">
        <PageInfo
            title={t('myCompanies')}
            buttonsSlot={
              <Button onClick={() => navigate('create')} iconLeft={<PlusIcon width={20} height={20} stroke="#fff" />}>
                {t('createNewCompany')}
              </Button>
            }
        />
        <DataTable rows={companies || []} columns={columns} />
      </div>
  );
};

export default CompaniesTable;