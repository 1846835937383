import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import Sidebar from '../../components/dashboard/Sidebar';
import Header from '../../components/dashboard/Header';
import './styles.sass';

const DashboardLayout = () => {
	const [isCollapsed, setIsCollapsed] = useState(false);

	const toggleSidebar = () => setIsCollapsed(!isCollapsed);

	return (
		<div className={`layout layout-dashboard ${isCollapsed ? 'collapsed' : ''}`}>
			<div className='container'>
				<Sidebar isCollapsed={isCollapsed} onToggle={toggleSidebar} />
				<div className='layout-dashboard__wrapper'>
					<Header />
					<Outlet />
				</div>
			</div>
		</div>
	);
};

export default DashboardLayout;