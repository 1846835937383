import axios from 'axios';
import axiosInstance from "../../index";

export const resetPassword = async (phoneNumber: string, otp: string, newPassword: string) => {
    const response = await axiosInstance.post('/auth/password-reset/confirm', {
        phone: phoneNumber,
        otp,
        newPassword
    });
    return response.data;
};