import { ReactComponent as ProfileLogo } from '../assets/global/profile-user.svg'
import { ReactComponent as OneIdLogo } from '../assets/global/one-id-logo.svg'
import { ReactComponent as TrashIcon } from '../assets/global/trash.svg';
import { ReactComponent as ArrowIcon } from '../assets/global/arrow_right.svg';
import { ReactComponent as FolderIcon } from '../assets/global/folder.svg';
import { ReactComponent as ExternalLink } from '../assets/global/external-link.svg';
import { ReactComponent as ImgIcon } from '../assets/global/img-icon.svg';
import { ReactComponent as ChevronDown } from '../assets/global/chevron-down.svg';
import { ReactComponent as ChevronIcon } from '../assets/global/chevron.svg'
import { ReactComponent as UploadIcon } from '../assets/global/upload-icon.svg'
import { ReactComponent as LangUz } from '../assets/global/lang-icons/uz.svg';
import { ReactComponent as LangRu } from '../assets/global/lang-icons/ru.svg';
import { ReactComponent as LangEn } from '../assets/global/lang-icons/en.svg';
import { ReactComponent as CloseIcon } from '../assets/global/close-icon.svg'
import { ReactComponent as FilterIcon } from '../assets/global/filter-icon.svg'
import { ReactComponent as PlusIcon } from '../assets/global/plus.svg'
import { ReactComponent as HelpIcon } from '../assets/global/help-circle.svg'
import { ReactComponent as BellIcon } from '../assets/global/bell.svg'
import { ReactComponent as ArrowLeft } from '../assets/global/arrow-left.svg'
import { ReactComponent as DashboardIcon } from '../assets/global/sidebar-icons/dashboard.svg'
import { ReactComponent as BoxIcon } from '../assets/global/sidebar-icons/box.svg'
import { ReactComponent as BuildingIcon } from '../assets/global/sidebar-icons/building.svg'
import { ReactComponent as TruckIcon } from '../assets/global/sidebar-icons/truck.svg'
import { ReactComponent as MapIcon } from '../assets/global/sidebar-icons/map.svg'
import { ReactComponent as DocIcon } from '../assets/global/sidebar-icons/news.svg'
import { ReactComponent as SettingsIcon } from '../assets/global/sidebar-icons/settings.svg'
import { ReactComponent as PenIcon } from '../assets/global/edit-icon.svg'
import { ReactComponent as JPGIcon } from '../assets/global/file-icons/jpg-icon.svg'
import { ReactComponent as InstagramIcon } from "../assets/global/coming-soon/instagram.svg";
import { ReactComponent as FacebookIcon } from "../assets/global/coming-soon/facebook.svg";
import { ReactComponent as TelegramIcon } from "../assets/global/coming-soon/telegram.svg";
import { ReactComponent as LinkedIn } from "../assets/global/coming-soon/linked-in.svg";
import { ReactComponent as ComingSoonMailIcon } from "../assets/global/coming-soon/mail.svg";
import { ReactComponent as ComingSoonMapIcon } from "../assets/global/coming-soon/map.svg";
import { ReactComponent as ComingSoonPhoneIcon } from "../assets/global/coming-soon/phone.svg";
import { ReactComponent as CheckIcon } from "../assets/global/check-icon.svg";
import Sponsor1 from "../assets/global/sponsors/sponsor-1.png";
import { ReactComponent as Sponsor2 } from "../assets/global/sponsors/sponsor-2.svg";
import { ReactComponent as Sponsor3 } from "../assets/global/sponsors/sponsor-3.svg";

import {ReactComponent as RefreshIcon } from "../assets/global/fi_refresh-cw.svg";
import {ReactComponent  as SideBarIcon } from "../assets/global/sidebarIcon.svg";
import  {ReactComponent as OpenEye} from "../assets/global/operneye.svg";
import  {ReactComponent as ClosedEye} from "../assets/global/closeeye.svg";

export {
  RefreshIcon,
  Sponsor1,
  Sponsor2,
  Sponsor3,
  CheckIcon,
  OpenEye,
  ClosedEye,
  ComingSoonMailIcon,
  ComingSoonMapIcon,
  ComingSoonPhoneIcon,
  LinkedIn,
  InstagramIcon,
  FacebookIcon,
  TelegramIcon,
  JPGIcon,
  PenIcon,
  DashboardIcon,
  BoxIcon,
  BuildingIcon,
  TruckIcon,
  MapIcon,
  DocIcon,
  SettingsIcon,
  ArrowLeft,
  PlusIcon,
  HelpIcon,
  BellIcon,
  ProfileLogo,
  OneIdLogo,
  TrashIcon,
  ArrowIcon,
  FolderIcon,
  ExternalLink,
  ImgIcon,
  ChevronDown,
  ChevronIcon,
  UploadIcon,
  LangRu,
  LangUz,
  LangEn,
  CloseIcon,
  FilterIcon,
  SideBarIcon
};
