import { ReactNode } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageInfo from "../../../../../components/dashboard/PageInfo"
import FlexWrapper from "../../../../../components/common/FlexWrapper";
import ContentWrapper from "../../../../../components/common/ContentWrapper";
import Button from "../../../../../components/common/Button";
import DataView from "../../../../../components/common/DataView";
import { PenIcon } from "../../../../../utils/icons";
import { useTranslation } from "react-i18next";
import {getUserIdFromToken} from "../../../../../utils/authUtils";
import useMe from "../../../../../api/hooks/useMe";

const DriverInfo = ({ driverName, navigationSlot }: { driverName: string; navigationSlot: ReactNode }) => {

  const navigate = useNavigate();
  const { companyID, driverID }= useParams();
  const { t } = useTranslation();
  console.log(driverID)
  const { data: me, isLoading } = useMe(driverID);
  console.log(me)

  if (isLoading) {
    return  <div className='page-drivers-view'>{t("loading")}</div>;
  }

  if (!me) {
    return <div className='page-drivers-view'>{t("dataNotFound")}</div>;
  }

  return (
      <div className='page-drivers-view'>
        <PageInfo backButton title={driverName} url={`/dashboard/companies/view/${companyID}/drivers`} />

        <FlexWrapper>
          {navigationSlot}

          <div className="page-content">
            <ContentWrapper>
              <div className="section-title">
                {t('driverDetails')}

                <Button onClick={()=> navigate(`/dashboard/drivers/${me.id}/edit`)} variant='clear'>
                  <PenIcon />
                </Button>
              </div>

              <FlexWrapper>
                <DataView style={{ flex: 1 }} label={t('driverName')} value={me.name} />
                <DataView style={{ flex: 1 }} label={t('driverSurname')} value={me.surename} />
                <DataView style={{ flex: 1 }} label={t('passport')} value='' />
              </FlexWrapper>

              <FlexWrapper>
                <DataView style={{ flex: 1 }} label={t('license')} value='' />
                <DataView style={{ flex: 1 }} label={t('licenseCategory')} value='' />
                <DataView style={{ flex: 1 }} label={t('address')} value='' />
              </FlexWrapper>
            </ContentWrapper>

            <ContentWrapper style={{ marginTop: 24 }}>
              <div className="section-title">
                {t('carOwnership')}

                <Button onClick={()=> navigate(`/dashboard/drivers/${me.id}/edit`)} variant='clear'>
                  <PenIcon />
                </Button>
              </div>

              <FlexWrapper>
                <DataView style={{ flex: 1 }} label={t('carOwnership')} value="" />
              </FlexWrapper>

              <FlexWrapper>
                <DataView style={{ flex: 1 }} label={t('carNumber')} value='' />
                <DataView style={{ flex: 1 }} label={t('carTechPassport')} value='' />
                <DataView style={{ flex: 1 }} label={t('trailer')} value='' />
              </FlexWrapper>

              <FlexWrapper>
                <DataView style={{ flex: 1 }} label={t('dispatcher')} value="" />
              </FlexWrapper>
            </ContentWrapper>
          </div>
        </FlexWrapper>
      </div>
  )
}

export default DriverInfo;