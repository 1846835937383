import axiosInstance from "../index";
import {useQuery, UseQueryResult} from "@tanstack/react-query";

interface User {
    id: string;
    phone: string;
    name: string;
    surename: string;
    roles: string[];
    createdAt: string;
    updatedAt: string;
    employeeAt: any[]; // Уточни, если нужно
    ownedCompanies: {
        id: string;
        name: string;
        inn: string;
        founded: string;
        ownerId: string;
        transportCount: string;
        employeeCount: string;
        createdAt: string;
        updatedAt: string;
    }[];
}

const fetchCompanies = async (userId: string): Promise<User> => {
    const response = await axiosInstance.get(`/user/${userId}`);
    return response.data;
};

const useMe = (userId: any): UseQueryResult<User, Error> => {
    return useQuery<User, Error>({
        queryKey: ['user', userId],
        queryFn: () => fetchCompanies(userId || ""),
        enabled: !!userId,
    });
};

export default useMe;