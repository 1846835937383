import { DataTableProps } from '../../../types/table.types'
import Filter from './Filter'
import Pagination from './Pagination'
import './styles.sass'

const DataTable = ({columns, rows, activePage, pagesCount, pagination, filterTitle}: DataTableProps) => {
  return (
    <div className='data-table'>
      <Filter title={filterTitle} />

      <div className='data-table__wrapper'>
        <div className="data-table__head">
          {columns.map(({headerName, id, flex, maxWidth, minWidth }) => (
            <div className="data-table__head-item" key={id} style={{flex, maxWidth, minWidth}}>
              {headerName}
            </div>
          ))}
        </div>

        <div className="data-table__body">
          {rows.map((row, idx) => {
            const rowColumns = columns.map(({renderCell, flex, maxWidth, minWidth, id}) => {
              return (
                <div 
                  key={id}
                  style={{maxWidth, minWidth, flex, fontSize:"14px" ,  padding: '19.5px 16px'}}
                >
                  {renderCell(row)}
                </div>
              )
            })
            return (
              <div className="data-table__body-cell" key={idx}>
                {rowColumns}
              </div>
            )
          })}
        </div>
      </div>
      
      {pagination && <Pagination />}
    </div>
  )
}

export default DataTable