import axiosInstance from "../../../index";
import { useQuery } from "@tanstack/react-query";

interface Employee {
    password(arg0: string, password: any): unknown;
    id: number | string;
    name: string;
    surname: string;
    phone: string;
    passportSeries: string;
    passportNumber: string;
    licenseSeries: string;
    licenseNumber: string;
}

const fetchEmployee = async (userId: any): Promise<Employee> => {
    const response = await axiosInstance.get(`/company-employee/user/${userId}`);
    return response.data;
};

export const useFetchEmployee = (userId: string | undefined) => {
    return useQuery<any, Error>({
        queryKey: ['employee', userId],
        queryFn: () => fetchEmployee(userId),
        enabled: !!userId,
    });
};