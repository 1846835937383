import { FC, useState, useEffect } from "react";
import DragNDrop from "../../../../../../components/common/DragNDrop";
import FlexWrapper from "../../../../../../components/common/FlexWrapper";
import Input from "../../../../../../components/common/Input";
import RadioInput from "../../../../../../components/common/RadioInput";

type TruckOwner = 'own' | 'workshop';

interface IndividualProps {
    currentStep: number;
    setFormData: (data: any) => void;
}

const Individual: FC<IndividualProps> = ({ currentStep, setFormData }) => {
    const [truckOwner, setTruckOwner] = useState<TruckOwner>('own');
    const [formData, setFormDataLocal] = useState<any>({});

    // Сохранение данных в локальное состояние и передача родительскому компоненту
    useEffect(() => {
        setFormData(formData);
    }, [formData, setFormData]);

    return (
        <>
            {currentStep === 1 && (
                <>
                    <p className="block-title">Passport ma’lumotlari</p>
                    <FlexWrapper style={{ marginTop: 24 }}>
                        <Input
                            label='Passport seriyasi'
                            placeholder="AB"
                            onChange={(e) => setFormDataLocal({ ...formData, passportSeries: e.target.value })}
                        />
                        <Input
                            label='Passport raqami'
                            placeholder="2134521"
                            onChange={(e) => setFormDataLocal({ ...formData, passportNumber: e.target.value })}
                        />
                    </FlexWrapper>
                    <FlexWrapper style={{ marginTop: 24 }}>
                        <DragNDrop
                            containerSize='sm'
                            label='Passport (old tomoni)'
                            fileTypeDescr='Format .pdf, .jpg or .png yuklang'
                        />
                        <DragNDrop
                            containerSize='sm'
                            label='Passport (orqa tomoni)'
                            fileTypeDescr='Format .pdf, .jpg or .png yuklang'
                        />
                    </FlexWrapper>
                </>
            )}

            {currentStep === 2 && (
                <>
                    <p className="block-title">Haydovchilik guvohnomasi ma’lumotlari</p>
                    <FlexWrapper style={{ marginTop: 24 }}>
                        <Input
                            label='Haydovchilik guvohnoma seriyasi'
                            placeholder="AF"
                            onChange={(e) => setFormDataLocal({ ...formData, driverLicenseSeries: e.target.value })}
                        />
                        <Input
                            label='Haydovchilik guvohnoma raqami'
                            placeholder="2134521"
                            onChange={(e) => setFormDataLocal({ ...formData, driverLicenseNumber: e.target.value })}
                        />
                    </FlexWrapper>
                    <FlexWrapper style={{ marginTop: 24 }}>
                        <DragNDrop
                            containerSize='sm'
                            label='Haydovchilik guvohnomasi (old tomoni)'
                            fileTypeDescr='Format .pdf, .jpg or .png yuklang'
                        />
                        <DragNDrop
                            containerSize='sm'
                            label='Haydovchilik guvohnomasi (orqa tomoni)'
                            fileTypeDescr='Format .pdf, .jpg or .png yuklang'
                        />
                    </FlexWrapper>
                </>
            )}

            {currentStep === 3 && (
                <>
                    <p className="block-title">Mashina kimga tegishli?</p>
                    <FlexWrapper wrapperStyle={{ marginTop: 10 }}>
                        <RadioInput
                            label="O'zimniki"
                            checked={truckOwner === 'own'}
                            onChange={() => setTruckOwner('own')}
                        />
                        <RadioInput
                            label='Ishxonaga tegishli'
                            checked={truckOwner === 'workshop'}
                            onChange={() => setTruckOwner('workshop')}
                        />
                    </FlexWrapper>

                    {truckOwner === 'own' && (
                        <>
                            <FlexWrapper style={{ marginTop: 24 }}>
                                <Input
                                    label='Mashina raqami'
                                    placeholder="01 A 234 BA"
                                    onChange={(e) => setFormDataLocal({ ...formData, carNumber: e.target.value })}
                                />
                                <Input
                                    label='Mashina texnik passport raqami'
                                    placeholder="AF 3425634"
                                    onChange={(e) => setFormDataLocal({ ...formData, carPassportNumber: e.target.value })}
                                />
                            </FlexWrapper>

                            <FlexWrapper style={{ marginTop: 24 }}>
                                <DragNDrop
                                    containerSize='sm'
                                    label='Mashina texnik passporti (old tomoni)'
                                    fileTypeDescr='Format .pdf, .jpg or .png yuklang'
                                />
                                <DragNDrop
                                    containerSize='sm'
                                    label='Mashina texnik passporti (orqa tomoni)'
                                    fileTypeDescr='Format .pdf, .jpg or .png yuklang'
                                />
                            </FlexWrapper>
                        </>
                    )}

                    {truckOwner === 'workshop' && (
                        <>
                            <DragNDrop
                                label='Mashina ishonchnomasi'
                                containerSize='sm'
                                fileTypeDescr='Format .pdf, .jpg or .png yuklang'
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default Individual;