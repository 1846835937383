import { InputHTMLAttributes } from 'react';
import './styles.sass';

interface RadioInputProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string;
    error?: any;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioInput = ({ label, error, onChange, ...props }: RadioInputProps) => {
    return (
        <label className='radio-container'>
            <input
                type='radio'
                className='radio-circle'
                onChange={onChange}
                {...props}
            />
            {label}
            {error && <div className="error">{error}</div>}
        </label>
    );
};

export default RadioInput;