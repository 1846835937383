import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from "react-hook-form";
import Button from '../../../components/common/Button';
import FlexWrapper from '../../../components/common/FlexWrapper';
import Input from '../../../components/common/Input';
import ProgressBar from '../../../components/common/ProgressBar';
import MaskedInput from "../../../components/common/MaskedInput";
import './styles.sass';
import { useRegister } from "../../../api/auth/mutation/regis";
import Cookies from 'js-cookie';
import OTP from "../../../components/authorization/OTP";

interface IFormInputs {
  name: string;
  surename: string;
  phone: string;
  password: string;
  repeatPassword: string;
  language? : string
}

const SignUp = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { register, formState: { errors }, handleSubmit, control, watch } = useForm<IFormInputs>();
  const [passwordStrength, setPasswordStrength] = useState(0);
  const password = watch('password');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [userData, setUserData] = useState<IFormInputs | null>(null);
  const [error, setError] = useState<string | null>(null);

  const { mutateAsync: registerUser } = useRegister() as any;

  useEffect(() => {
    const checkPasswordStrength = (password: string) => {
      const conditions = [
        /.{8,}/,
        /(?=.*[a-z])(?=.*[A-Z])/,
        /[0-9]/,
        /[!@#$%^&*(),.?":{}|<>]/,
      ];
      const passedConditions = conditions.reduce((acc, condition) => acc + (condition.test(password) ? 1 : 0), 0);
      setPasswordStrength(passedConditions);
    };

    checkPasswordStrength(password);
  }, [password]);

  // Обработчик отправки OTP
  const handleOtpSubmit = async () => {
    const otpValue = otp.join("");
    if (otpValue.length !== 6) {
      setError(t("Please enter a valid OTP"));
      return;
    }

    if (!userData) {
      setError(t("User data is missing"));
      return;
    }

    try {
      const response = await registerUser({ ...userData, otp: otpValue });
      const accessToken = response?.accessToken;
      const refreshToken = response?.refreshToken?.token;

      if (accessToken) {
        Cookies.set('accessToken', accessToken, { expires: 30, secure: true, sameSite: 'Strict' });
      }

      if (refreshToken) {
        Cookies.set('refresh_token', refreshToken, { expires: 30, secure: true, sameSite: 'Strict' });
      }

      navigate('/auth/additional-info');
    } catch (err) {
      setError(t("OTP verification failed"));
    }
  };

  // Обработчик регистрации
  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    try {
      if (!data.phone.startsWith('+')) {
        data.phone = `+${data.phone}`;
      }

      const response = await registerUser({ ...data, language: "uz" });
        setUserData({ ...data, language: "uz" }); // Сохраняем данные пользователя
        setCurrentStep(2); // Переход на следующий шаг
        console.log(currentStep)
    } catch (err) {
      console.error(err);
      setError(t("Registration failed"));
    }
  };

  return (
      <div className='page-sign-up'>
        <h1 className="page-sign-up__title">{t("SignUp")}</h1>
        <ProgressBar currentStep={currentStep} stepsCount={2} style={{ marginTop: 24 }} />

        {currentStep === 1 && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <FlexWrapper>
                <Input
                    label={t('FirstName')}
                    required
                    {...register('name', {
                      required: t("Please enter your first name"),
                      minLength: { value: 4, message: t("Minimum length is 4 characters") },
                      validate: { noSpaces: (value) => value.trim() !== "" || t("Field cannot be just spaces") },
                    })}
                    error={errors.name?.message}
                />
                <Input
                    label={t('LastName')}
                    required
                    {...register('surename', {
                      required: t("Please enter your surname"),
                      minLength: { value: 4, message: t("Minimum length is 4 characters") },
                      validate: { noSpaces: (value) => value.trim() !== "" || t("Field cannot be just spaces") },
                    })}
                    error={errors.surename?.message}
                />
              </FlexWrapper>
              <MaskedInput
                  label={t("PhoneNumber")}
                  name="phone"
                  control={control}
                  mask="+{998} (00) 000 00 00"
                  unmask
                  placeholder='+998 (00) 000 00 00'
                  required
                  rules={{
                    required: t("Please enter your phone number."),
                    pattern: { value: /^[0-9]+$/, message: t("Phone number should only contain numbers.") },
                    validate: { exactLength: (value = '') => value.length === 12 || t("Phone number must be exactly 12 digits.") },
                  }}
              />
              <Input
                  label={t("Password")}
                  type="password"
                  isPasswordVisible={passwordVisible}
                  onPasswordToggle={setPasswordVisible}
                  {...register('password', {
                    required: t("Please enter password"),
                    minLength: { value: 8, message: t("Minimum length is 8 characters") },
                    validate: { noSpaces: (value) => value.trim() !== "" || t("Field cannot be just spaces") },
                  })}
                  error={errors.password?.message}
              />
              <Input
                  label={t("RepeatPassword")}
                  type="password"
                  isPasswordVisible={passwordVisible}
                  onPasswordToggle={setPasswordVisible}
                  {...register('repeatPassword', {
                    required: t("Please confirm the password"),
                    validate: { matchesPassword: (value) => value === watch('password') || t("Passwords do not match") },
                  })}
                  error={errors.repeatPassword?.message}
              />
              <Button size='lg' type='submit' style={{ marginTop: 16 }} fullWidth >
                { t("SignUp")}
              </Button>
            </form>
        )}

        {currentStep === 2 && (
            <div className="otp-step">
              <h2>{t("EnterOTP")}</h2>
              <OTP otp={otp} setOtp={setOtp} error={!!error} />
              {error && <p style={{ color: 'red' }}>{t("OTPError")}</p>}
              <Button size='lg' style={{ marginTop: 16 }} fullWidth onClick={handleOtpSubmit}>
                {t("SubmitOTP")}
              </Button>
            </div>
        )}
      </div>
  );
};

export default SignUp;