import axiosInstance from "../../../index";
import { useQuery } from "@tanstack/react-query";

interface Trailer {
    id: number | string;
    driverName: string;
    passportData: string;
    hgId: string;
    carNumber: string;
    cargoCount: number;
    status: string;
}

const fetchDrivers = async (companyId: string): Promise<Trailer[]> => {
    const response = await axiosInstance.get(`/trailers/?companyId=${companyId}`);
    return response.data;
};

export const useCompanyTrailers = (companyId: string) => {
    return useQuery<Trailer[], Error>({
        queryKey: ['transport', companyId],
        queryFn: () => fetchDrivers(companyId),
        enabled: !!companyId,
        refetchOnWindowFocus: true,
    });
};