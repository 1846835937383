import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import AppRoutes from './routes'
import './config/i18n/i18n'
import './styles/global.sass'
import {QueryClientProvider} from "@tanstack/react-query";
import queryClient from "./config/react-query/queryClient";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<RouterProvider router={AppRoutes} />
			<ToastContainer closeOnClick />
		</QueryClientProvider>
	</React.StrictMode>
)
