import {ReactNode, useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {ChevronDown, PenIcon, PlusIcon, TrashIcon} from "../../../../../utils/icons";
import PageInfo from '../../../../../components/dashboard/PageInfo'
import FlexWrapper from '../../../../../components/common/FlexWrapper'
import Button from "../../../../../components/common/Button";
import DataTable from "../../../../../components/dashboard/DataTable";
import {driversRows} from "../../../../../utils/consts";
import {Column, DriversRow, Transport} from "../../../../../types/table.types";
import Tag from "../../../../../components/common/Tag";
import {useTranslation} from "react-i18next";
import {fetchCompanyById} from "../../../../../api/hooks/company/useSingleCompany";
import {useCompanyTrailers} from "../../../../../api/hooks/company/trailers/useTrailers";

const CompanyTrailers = ({ navigationSlot }: {navigationSlot: ReactNode}) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {companyID} = useParams();
  const [company, setCompany] = useState<any>([]);

  const { data: driversRows, isLoading, error, refetch } = useCompanyTrailers(companyID || "");

  useEffect(() => {
    const loadCompany = async () => {
      try {
        const companyData = await fetchCompanyById(companyID!); // Fetch company data
        setCompany(companyData.name);
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    };

    loadCompany();
    // Принудительно обновляем данные при изменении маршрута
    refetch();
  }, [companyID, refetch]); // refetch будет вызываться при изменении companyID


  const columns: Column[] = [
    {
      id: 'driver-name',
      flex: 2,
      headerName: t('driverName'),
      renderCell(row: Transport) {
        return (
            <div
                style={{ cursor: 'pointer' , textDecoration : "underline" , color:"#3B7CCC" }}
                onClick={() => navigate(`/dashboard/trucks/${row.id}/view`, {
                  state: {
                    name: row.name,
                    status: row.status,
                  }
                })}
            >
              {row.name}
            </div>
        )
      },
    },
    {
      id: 'passport-number',
      flex: 1,
      headerName: t('passportNumber'),
      renderCell(row: DriversRow) {
        return <div>{row.passportData}</div>
      },
    },
    {
      id: 'hg-id',
      flex: 1,
      headerName: t('hgId'),
      renderCell(row: Transport) {
        return <div>{row.serial}</div>
      },
    },
    {
      id: 'car-number',
      flex: 1,
      headerName: t('carNumber'),
      renderCell(row: Transport) {
        return <div>{row.number}</div>
      },
    },
    {
      id: 'cargo-number',
      flex: 1,
      headerName: t('cargoCount'),
      renderCell(row: DriversRow) {
        return <div>{row.cargoCount}</div>
      },
    },
    {
      id: 'status',
      flex: 1,
      headerName: t('status'),
      renderCell(row: DriversRow) {
        return <div>{row.status === 'Aktiv'
            ? <Tag text={t(row.status)} color='primary' />
            :  <Tag text={t(row.status)} color='error' />
        }</div>
      },
    },
    {
      id: 'action',
      headerName: '',
      flex: 0.5,
      renderCell(row: DriversRow) {
        return (
            <FlexWrapper>
              <Button variant='clear'>
                <PenIcon />
              </Button>
              <Button variant='clear'>
                <TrashIcon />
              </Button>
            </FlexWrapper>
        )
      },
    },
  ];

  return (
    <div className='page-companies-view'>
      <PageInfo
          title={company}
        backButton
        url='/dashboard/companies'
        buttonsSlot={
          <FlexWrapper>
            <Button variant='secondary' iconRight={ <ChevronDown width={16} height={16} /> }>
              Ommaviy o’zgartirish
            </Button>
            <Button onClick={() => navigate(`/dashboard/trailers/${companyID}/create`)} iconLeft={ <PlusIcon stroke="#fff" width={16} height={16} /> }>
              Yangi tirkama qo’shish
            </Button>
          </FlexWrapper>
        }
      />

      <FlexWrapper>
        {navigationSlot}

        <div className="page-content">
          <DataTable columns={columns} rows={driversRows || []} filterTitle='Tirkamalar' />
        </div>
      </FlexWrapper>
    </div>
  )
}

export default CompanyTrailers
