import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ContentWrapper from '../../../components/common/ContentWrapper';
import PageInfo from '../../../components/dashboard/PageInfo';
import Button from '../../../components/common/Button';
import FlexWrapper from '../../../components/common/FlexWrapper';
import Input from '../../../components/common/Input';
import AmountPicker from '../../../components/common/AmountPicker';
import Combobox from '../../../components/common/Combobox';
import DragNDrop from '../../../components/common/DragNDrop';
import { PlusIcon, TrashIcon } from "../../../utils/icons";
import { CargoAmounts2 } from '../../../utils/consts';
import './styles.sass';
import { useCreateCompany } from "../../../api/hooks/company/useCreateCompany";
import { useCreateRequisites } from "../../../api/hooks/company/useCreateRequisites";
import { fetchCompanyById } from "../../../api/hooks/company/useSingleCompany";
import {CompanyData, Requisite} from "../../../types/company.types";
import { useTranslation } from "react-i18next";

const EditCompanies = () => {
    const navigate = useNavigate();
    const { companyID } = useParams<{ companyID: string }>();
    const [companyName, setCompanyName] = useState<string>("");
    const [companyOwner, setCompanyOwner] = useState<string>("");
    const [inn, setInn] = useState<string>('');
    const [requisites, setRequisites] = useState<Requisite[]>([{
        bank: '',
        accountNumber: '',
        mfo: '',
        currency: 'UZS',
    }]);
    const [employees, setEmployee] = useState('');
    const [trucks, setTrucks] = useState('');
    const [carYear, setCarYear] = useState<string>('2020');
    const [yearOptions, setYearOptions] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const validCurrencies = ['RUB', 'USD', 'EUR', 'KZT', 'UZS', 'CNY'];
    const { mutateAsync: createRequisites } = useCreateRequisites();
    const { t } = useTranslation();

    useEffect(() => {
        const loadCompany = async () => {
            try {
                const data: CompanyData = await fetchCompanyById(companyID!);
                setCompanyName(data.name || "");
                setCompanyOwner(data.owner.name || "");
                setInn(data.inn || "");
                setTrucks(String(data.transportCount || ""));
                setEmployee(String(data.employeeCount || ""));
                setCarYear(String(data.founded || '2020'));
                setRequisites(data.requisites || [{
                    bank: '',
                    accountNumber: '',
                    mfo: '',
                    currency: 'UZS',
                }]);
            } catch (error) {
                console.error('Error fetching company data:', error);
            } finally {
                setLoading(false);
            }
        };

        loadCompany();
    }, [companyID]);

    // Генерация списка годов
    useEffect(() => {
        const generateYears = () => {
            const currentYear = new Date().getFullYear();
            const years = [];
            for (let year = currentYear; year >= 1990; year--) {
                years.push({ label: year, value: year });
            }
            return years;
        };
        setYearOptions(generateYears());
    }, []);

    // Изменение реквизитов
    const handleRequisiteChange = (idx: number, field: keyof Requisite, value: string) => {
        if (field === 'currency' && !validCurrencies.includes(value)) {
            console.error('Invalid currency');
            return;
        }
        const newRequisites = [...requisites];
        newRequisites[idx][field] = value;
        setRequisites(newRequisites);
    };

    // Добавление нового реквизита
    const handleCreateRequisites = () => {
        setRequisites([...requisites, { bank: '', accountNumber: '', mfo: '', currency: 'UZS' }]);
    };

    // Сохранение данных
    const onSubmit = async () => {
        const companyData = {
            name: companyName,
            inn,
            founded: carYear,
            employeeCount: employees,
            transportCount: trucks,
        };

        try {
            // Здесь создание компании (если нужно)
            for (let idx = 0; idx < requisites.length; idx++) {
                const requisitesData = {
                    bank: requisites[idx].bank,
                    accountNumber: requisites[idx].accountNumber,
                    mfo: requisites[idx].mfo,
                    currency: requisites[idx].currency,
                    companyId: companyID || "", // Указываем ID компании
                };
                await createRequisites(requisitesData);
            }
            navigate(-1);
        } catch (error) {
            console.error('Error during company and requisites creation', error);
        }
    };

    return (
        <div className="page-compaines-create">
            <PageInfo
                title="Yangi korxona yaratish"
                backButton
                buttonsSlot={
                    <Button onClick={onSubmit}>Saqlash</Button>
                }
            />

            <ContentWrapper rowGap={56}>
                <div>
                    <h3 className="section-title">Korxona ma'lumotlari</h3>
                    <FlexWrapper style={{ marginTop: 16 }}>
                        <Input
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            label="Korxona nomi"
                        />
                        <Input
                            value={companyOwner}
                            onChange={(e) => setCompanyOwner(e.target.value)}
                            label="Korxona Rahbari F.I.SH"
                        />
                        <Input
                            value={inn}
                            onChange={(e) => setInn(e.target.value)}
                            label="INN"
                        />
                    </FlexWrapper>

                    <FlexWrapper style={{ marginTop: 16 }}>
                        <AmountPicker
                            label="Transportlar soni"
                            amounts={CargoAmounts2}
                            onAmountClick={(amount) => setTrucks(amount)}
                            selectedAmount={trucks}
                        />
                        <AmountPicker
                            label="Ishchilar soni"
                            amounts={CargoAmounts2}
                            onAmountClick={(amount) => setEmployee(amount)}
                            selectedAmount={employees}
                        />
                        <Combobox
                            label={t('yearOfManufactureLabel')}
                            options={yearOptions}
                            onSelect={(value: any) => setCarYear(value)}
                            placeholder={t('yearOfManufacturePlaceholder')}
                            defaultValue={carYear}
                        />
                    </FlexWrapper>

                    <FlexWrapper style={{ marginTop: 16 }}>
                        <DragNDrop label="Guvohnomasi fayli" containerSize="sm" />
                        <DragNDrop label="Rahbar passporti (oldi)" containerSize="sm" />
                        <DragNDrop label="Rahbar passporti (orqa)" containerSize="sm" />
                    </FlexWrapper>
                </div>

                <div>
                    <h3 className="section-title">Rekvizitlar</h3>
                    {requisites.map((requisite, idx) => (
                        <ContentWrapper background="ltblue" style={{ marginTop: 16 }} key={idx}>
                            <FlexWrapper alignItems="flex-end">
                                <Input
                                    value={requisite.bank}
                                    onChange={(e) => handleRequisiteChange(idx, 'bank', e.target.value)}
                                    label="Bank nomi"
                                    containerStyle={{ flex: 3 }}
                                />
                                <Input
                                    value={requisite.accountNumber}
                                    onChange={(e) => handleRequisiteChange(idx, 'accountNumber', e.target.value)}
                                    label="Hisob raqam"
                                    containerStyle={{ flex: 3 }}
                                />
                                <Input
                                    value={requisite.mfo}
                                    onChange={(e) => handleRequisiteChange(idx, 'mfo', e.target.value)}
                                    label="MFO"
                                    containerStyle={{ flex: 2 }}
                                />
                                <Combobox
                                    label="Valyuta"
                                    defaultValue={requisite.currency}
                                    onSelect={(e: any) => handleRequisiteChange(idx, 'currency', e.value)}
                                    options={[
                                        { label: 'UZS', value: 'UZS' },
                                        { label: 'USD', value: 'USD' },
                                        { label: 'RUB', value: 'RUB' },
                                    ]}
                                />
                                <Button
                                    variant="clear"
                                    className="remove-btn"
                                    disabled={idx === 0}
                                    onClick={() => {
                                        const newArray = requisites.filter((_, i) => i !== idx);
                                        setRequisites(newArray);
                                    }}
                                >
                                    <TrashIcon />
                                </Button>
                            </FlexWrapper>
                        </ContentWrapper>
                    ))}
                    <Button
                        variant="underline"
                        style={{ marginTop: 16 }}
                        className="create-requisite-btn"
                        iconLeft={<PlusIcon />}
                        onClick={handleCreateRequisites}
                    >
                        Yana qo'shish
                    </Button>
                </div>
            </ContentWrapper>
        </div>
    );
};

export default EditCompanies;