// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.truck-delivery-tab .content-wrapper {
  margin-top: 16px;
}

.truck-delivery-tab {
  background-color: #FFFFFF;
  border: 1px solid #DEE2E6;
  border-radius: 4px;
}
.truck-delivery-tab .form-body {
  padding: 16px;
}
.truck-delivery-tab .tabs-header {
  display: flex;
  height: 46px;
  justify-content: space-around;
}
.truck-delivery-tab .tabs-header .tab-button {
  flex: 1 1;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  background-color: #FFFFFF;
  border: none;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  text-align: center;
  transition: color 0.3s, border-bottom 0.3s;
}
.truck-delivery-tab .tabs-header .tab-button.active {
  border-bottom: 1px solid #3B7CCC;
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/pages/Auth/Additional/RolesTabs/TruckDeliveryTab/styles.sass"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;;AAyBA;EACE,yBAAA;EACA,yBAAA;EACA,kBAAA;AAtBF;AAuBE;EACE,aAAA;AArBJ;AAsBE;EACE,aAAA;EACA,YAAA;EACA,6BAAA;AApBJ;AAsBI;EACE,SAAA;EACA,aAAA;EACA,eAAA;EACA,iBAAA;EACA,WAAA;EACA,yBAAA;EACA,YAAA;EACA,6BAAA;EACA,eAAA;EACA,kBAAA;EACA,0CAAA;AApBN;AAsBM;EAEE,gCAAA;EACA,uBAAA;AArBR","sourcesContent":[".truck-delivery-tab\n  .content-wrapper\n    margin-top: 16px\n\n// Переменные цветов\n$primary-color: #007bff\n$border-color: #ccc\n$background-color: #f5f5f5\n$font-color: #333\n\n// Миксин для кнопки\n=button-style($isActive: false)\n  padding: 8px 12px\n  font-size: 14px\n  color: $font-color\n  border: 1px solid $border-color\n  background-color: $background-color\n  border-radius: 4px\n  cursor: pointer\n  transition: background-color 0.3s, color 0.3s\n\n  @if $isActive\n    color: white\n    background-color: $primary-color\n    border-color: $primary-color\n\n// Основной контейнер табов\n.truck-delivery-tab\n  background-color: #FFFFFF\n  border: 1px solid #DEE2E6\n  border-radius: 4px\n  .form-body\n    padding: 16px\n  .tabs-header\n    display: flex\n    height: 46px\n    justify-content: space-around\n    //margin-bottom: 48px\n    .tab-button\n      flex: 1\n      padding: 10px\n      font-size: 16px\n      font-weight: bold\n      color: #333\n      background-color: #FFFFFF\n      border: none\n      border-bottom: 1px solid #ccc\n      cursor: pointer\n      text-align: center\n      transition: color 0.3s, border-bottom 0.3s\n\n      &.active\n        //color: #3B7CCC\n        border-bottom: 1px solid #3B7CCC\n        background-color: white\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
