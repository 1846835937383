import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axiosInstance from '../index';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export const useCustomMutation = <TData, TVariables>(
    url: string,
    options?: UseMutationOptions<TData, unknown, TVariables>
) => {
    const { t } = useTranslation();

    return useMutation({
        mutationFn: async (data: TVariables): Promise<TData> => {
            const response = await axiosInstance.post(url, data);
            return response.data;
        },
        onSuccess: (data: any) => {
            toast.success(t(data?.message || 'Success'));
        },
        onError: (error: any) => {
            toast.error(t(error.response?.data?.message || 'Error'));
        },
        ...options,
    });
};