import { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import OneIdButton from '../../../components/authorization/OneIdButton';
import Button from '../../../components/common/Button';
import Input from '../../../components/common/Input';
import { useLogin } from '../../../api/auth/mutation/login';
import OTP from "../../../components/authorization/OTP"; // Импорт компонента OTP
import './styles.sass';
import { useTranslation } from 'react-i18next';

const defaultInputsValues = {
  phone: '',
  password: ''
};

const SignIn = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [formFieldsValue, setFormFieldsValue] = useState(defaultInputsValues);
  const [formError, setFormError] = useState('');
  const [authError, setAuthError] = useState('');
  const [currentStep, setCurrentStep] = useState(1); // Управление шагами
  const [otp, setOtp] = useState(["", "", "", "", "", ""]); // Хранение кода OTP

  const { mutateAsync: loginUser, isLoading: authPending, error: authMutationError } = useLogin() as any;

  // Обработчик изменения значений полей
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormFieldsValue({
      ...formFieldsValue,
      [name]: value
    });

    setAuthError('');
  };

  // Отправка данных для входа
  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formFieldsValue.password && formFieldsValue.phone) {
      try {
        const response = await loginUser({
          phone: formFieldsValue.phone,
          password: formFieldsValue.password,
          language: 'uz' // Добавление языка
        });

          setCurrentStep(2); // Переход на следующий шаг
      } catch (err) {
        setAuthError(t('Login failed'));
      }
    } else {
      setFormError(t('Please fill in all fields'));
    }
  };

  // Подтверждение OTP
  const handleOtpSubmit = async () => {
    const otpValue = otp.join("");

    if (otpValue.length !== 6) {
      setAuthError(t("Please enter a valid OTP"));
      return;
    }

    try {
      const response = await loginUser({
        phone: formFieldsValue.phone,
        password : formFieldsValue.password,
        otp: otpValue // Передача кода OTP
      });

        navigate('/dashboard'); // Навигация в приложение
    } catch (err) {
      setAuthError(t("OTP verification failed"));
    }
  };

  useEffect(() => {
    if (authMutationError) {
      setAuthError(t('Incorrect login or password'));
    }
  }, [authMutationError]);

  const navigateTo = (path: string) => () => {
    navigate(path);
  };

  return (
      <div className="page-sign-in">
        <h1>{t('SignIn')}</h1>

        {currentStep === 1 && (
            <form onSubmit={onSubmit}>
              <Input
                  name="phone"
                  label={t('PhoneNumber')}
                  placeholder="+998 (99) 111-22-33"
                  value={formFieldsValue.phone}
                  onChange={onChangeHandler}
                  error={!!formError || !!authError}
                  style={{ borderColor: !!authError ? 'red' : '' }}
              />
              {authError && <p className="form-error">{authError}</p>}

              <Input
                  name="password"
                  label={t('Password')}
                  placeholder="******"
                  type="password"
                  value={formFieldsValue.password}
                  onChange={onChangeHandler}
                  error={!!formError || !!authError}
                  style={{ borderColor: !!authError ? 'red' : '' }}
              />
              {formError && <p className="form-error">{formError}</p>}

              <Button variant="underline" style={{ marginTop: 16 }} onClick={navigateTo('/auth/restore')}>
                {t('ForgotPassword')}
              </Button>

              <Button isLoading={authPending} type="submit" fullWidth size="lg" style={{ marginTop: 50 }}>
                {t('SignInButton')}
              </Button>

              <div className="page-sign-in__question">
                <p>
                  {t('AccountNotExist')}{' '}
                  <span className="text-underline" onClick={navigateTo('/auth/sign-up')}>
                {t('SignUp')}
              </span>
                </p>
                <div>
                  <hr />
                  {t('Or')}
                  <hr />
                </div>
              </div>

              <OneIdButton />
            </form>
        )}

        {currentStep === 2 && (
            <div className="otp-step">
              <h2>{t("EnterOTP")}</h2>
              <OTP otp={otp} setOtp={setOtp} error={!!authError} />
              {authError && <p className="form-error">{authError}</p>}

              <Button size="lg" style={{ marginTop: 16 }} fullWidth onClick={handleOtpSubmit}>
                {t("SubmitOTP")}
              </Button>
            </div>
        )}
      </div>
  );
};

export default SignIn;