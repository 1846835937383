import { FormEvent, useState } from 'react';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../components/common/Button';
import OTP from '../../../components/authorization/OTP';
import './styles.sass';
import ProgressBar from "../../../components/common/ProgressBar";

interface LocationState {
    phoneNumber?: string;
}

const Verification = () => {
    const navigate = useNavigate();
    const location: Location<LocationState> = useLocation();
    const phoneNumber = location.state?.phoneNumber;
    const [otp, setOtp] = useState(Array(6).fill(''));
    const [formError, setFormError] = useState('');

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const joinedOtp = otp.join("");

        if (joinedOtp.length === 6) {
            navigate('/auth/new-password', { state: { phoneNumber, otp: joinedOtp } }); // передаем OTP и номер телефона
        } else {
            setFormError('Please enter the OTP code');
        }
    };

    return (
        <div className='page-verification'>
            <h1 className='page-verification__title'>Tasdiqlash</h1>
            <ProgressBar stepsCount={3} currentStep={2} style={{ marginTop: 24 }} />
            <p className='page-verification__descr'>
                Parolni tiklash uchun bir martalik tasdiqlash kodi
                <br />
                <span className='phone'>{phoneNumber}</span> raqamiga yuborildi.
            </p>
            <Button onClick={() => navigate(-1)} variant='underline' style={{ marginTop: 8 }}>
                Raqamni o’zgartirish
            </Button>
            <form onSubmit={onSubmit}>
                <OTP otp={otp} setOtp={setOtp} error={!!formError} />
                {formError && <p className='form-error'>{formError}</p>}
                <p className='timer'>
                    Kodni yana yuborish{' '}
                    <span className='timer__time'>01:59</span>
                </p>
                <Button size='lg' fullWidth type='submit' style={{ marginTop: 40 }}>
                    Tasdiqlash
                </Button>
            </form>
        </div>
    );
};

export default Verification;