import { useMutation } from '@tanstack/react-query';
import axiosInstance from "../../../index";

export const useUpdateUser = (userID: string | undefined) => {
    const mutation = useMutation({
        mutationFn: (updatedData: any) => {
            return axiosInstance.patch(`/user/${userID}`, updatedData); // Отправляем запрос
        },
        onSuccess: (data) => {
            console.log('User updated successfully:', data);
        },
        onError: (error) => {
            console.error('Error updating user:', error);
        },
    });

    return mutation;
};