import axiosInstance from "../../index";

interface RestorePasswordResponse {
    message: string;
    success: boolean;
}

export const restorePassword = async (phone: string): Promise<RestorePasswordResponse> => {
    const response = await axiosInstance.post('auth/password-reset/initiate ', { phone });
    return response.data;
};