import React, {CSSProperties} from 'react';
import { IMaskInput } from 'react-imask';
import { useController, Control } from 'react-hook-form';
import '../Input/styles.sass'
import classNames from "classnames";

interface MaskedInputProps {
  label: string;
  name: string;
  placeholder?: string;
  control: Control<any>;
  rules?: object;
  mask: string;
  unmask?: boolean;
  required?: boolean;
  containerStyle?: CSSProperties;
  formatAsPhoneNumber?: boolean;
}

const MaskedInput = ({
  label,
  name,
  control,
  rules = {},
  required,
  mask,
  unmask,
  placeholder,
  containerStyle,
 }: MaskedInputProps) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue: '',
  });

  const handleAccept = (value: string) => {
      onChange(value);
  };

  return (
    <div className="input-container" style={containerStyle}>
      <label className="input-label">
        {label && (
          <p className="input-label__text">
            {label}
            {required && <span>*</span>}
          </p>
        )}

        <IMaskInput
          mask={mask}
          unmask={unmask}
          value={value}
          placeholder={placeholder}
          onAccept={handleAccept}
          className={classNames('input', { 'input--error': error })}
        />
      </label>

      {error && <div className="error-message">{error.message}</div>}
    </div>
  );
};

export default MaskedInput;
