import { useLocation, useNavigate } from "react-router-dom"
import Logo from "../../common/Logo"
import Button from "../../common/Button";
import { ProfileLogo } from "../../../utils/icons";
import './styles.sass'

const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleSignInClick = () => {
    navigate('/auth/sign-in');
  }

  return (
    <header className='auth-header'>
      <Logo height='48px' width='216px'/>

      {pathname == '/auth/sign-up' && (
        <Button
          variant='outline'
          onClick={handleSignInClick}
          className='auth-header__button'
          iconLeft={<ProfileLogo />}
        >
          Kirish
        </Button>
      )}
    </header>
  )
}

export default Header
