import { ReactNode } from 'react'
import { UploadIcon } from "../../../../../utils/icons";
import Accardion from '../../../../../components/common/Accardion'
import ContentWrapper from '../../../../../components/common/ContentWrapper'
import FlexWrapper from '../../../../../components/common/FlexWrapper'
import PageInfo from '../../../../../components/dashboard/PageInfo'
import Button from '../../../../../components/common/Button'
import DragNDrop from '../../../../../components/common/DragNDrop'
import { Modal } from '../../../../../components/common/Modal'
import Combobox from '../../../../../components/common/Combobox'
import useModal from '../../../../../hooks/useModal'
import { createDocSelect } from '../../../../../utils/consts'
import { useTranslation } from 'react-i18next'  // Import the translation hook
import './styles.sass'

const CompanyDocs = ({ navigationSlot }: { navigationSlot: ReactNode }) => {
    const createDocModal = useModal();
    const removeDocModal = useModal();
    const { t } = useTranslation();

    return (
        <div className='page-companies-view'>
            <PageInfo
                title='OOO UMID AVTO LOGISTICS'
                backButton
                url='/dashboard/companies'
                buttonsSlot={
                    <Button
                        variant='secondary'
                        onClick={createDocModal.openModal}
                        iconLeft={<UploadIcon width={16} height={16} />}
                    >
                        {t('new_document_upload')}
                    </Button>
                }
            />

            <FlexWrapper>
                {navigationSlot}

                <div className="page-content">
                    <ContentWrapper className='company-docs'>
                        <h3 className="section-title">{t('company_docs')}</h3>

                        <Accardion onRemoveModal={removeDocModal} />
                    </ContentWrapper>
                </div>
            </FlexWrapper>

            {createDocModal.isOpen && (
                <Modal onClose={createDocModal.closeModal} title={t('new_document_upload')}>
                    <p className="create-doc__label">{t('new_file_name')}</p>
                    <Combobox placeholder={t('driver_license')} options={createDocSelect} />

                    <p className="create-doc__label">{t('document')}</p>
                    <DragNDrop containerSize='sm' />

                    <div className="modal__btns">
                        <Button variant='secondary' fullWidth>
                            {t('cancel')}
                        </Button>

                        <Button fullWidth>
                            {t('save')}
                        </Button>
                    </div>
                </Modal>
            )}

            {removeDocModal.isOpen && (
                <Modal
                    onClose={removeDocModal.closeModal}
                    titlePosition='center'
                    title={t('confirm_delete')}
                >
                    <p className='remoev-doc__desc'>
                        {t('delete_confirmation')}
                    </p>

                    <div className="modal__btns">
                        <Button variant='secondary' fullWidth>
                            {t('no_delete')}
                        </Button>
                        <Button fullWidth>{t('yes_delete')}</Button>
                    </div>
                </Modal>
            )}
        </div>
    )
}

export default CompanyDocs;