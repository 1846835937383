import AmountPicker from '../../../../../components/common/AmountPicker';
import { CargoAmounts } from '../../../../../utils/consts';
import Combobox from "../../../../../components/common/Combobox";
import Button from "../../../../../components/common/Button";

interface CargoOwnerTabProps {
  selectedAmount: string;
  setSelectedAmount: (amount: string) => void;
}

const CargoOwnerTab = ({selectedAmount, setSelectedAmount}: CargoOwnerTabProps) => {
  return (
      <div>
          <div className={"truck-delivery-tab"} style={{padding:'16px'}}>
              <AmountPicker
                  label='Oyiga qancha  yuk chiqarasiz? (tonna)'
                  amounts={CargoAmounts}
                  selectedAmount={selectedAmount}
                  onAmountClick={(amount: string) => {
                      setSelectedAmount(amount);
                  }}
              />
          </div>

          <br/>
          <br/>
          <Combobox
              label='Biz haqimizda qayerdan eshitdingiz?'
              options={[
                  {label: 'Do\'stim taklif qildi', value: 0},
                  {label: 'Ijtimoiy tarmoqlardan', value: 1},
                  {label: 'Websayt sahifalaridan', value: 2}
              ]}
              onSelect={(value) => {
                  console.log(value)
              }}
          />

          <Button type='submit' fullWidth size='lg' disabled style={{marginTop: 24}}>
              Yakunlash
          </Button>
      </div>
  )
}

export default CargoOwnerTab