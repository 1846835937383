// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar {
  width: 100%;
  display: flex;
  align-items: center;
}
.progress-bar--sm {
  gap: 4px;
}
.progress-bar--sm .progress-bar__track {
  width: 100%;
  height: 2px;
  border-radius: 4px;
}
.progress-bar--md {
  gap: 8px;
}
.progress-bar--md .progress-bar__track {
  flex: 0.25 1;
  height: 8px;
  border-radius: 6px;
}
.progress-bar__track {
  background-color: #D9D9D9;
  border-radius: 6px;
  overflow: hidden;
}
.progress-bar__progress {
  height: 100%;
  background-color: var(--primary);
  transition: all 0.3s ease-in-out;
}
.progress-bar__progress.progress-bar--red {
  background-color: #C00F0C;
}
.progress-bar__progress.progress-bar--yellow {
  background-color: #efab2b;
}`, "",{"version":3,"sources":["webpack://./src/components/common/ProgressBar/styles.sass"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;AACF;AACE;EACE,QAAA;AACJ;AACI;EACE,WAAA;EACA,WAAA;EACA,kBAAA;AACN;AAEE;EACE,QAAA;AAAJ;AAEI;EACE,YAAA;EACA,WAAA;EACA,kBAAA;AAAN;AAGE;EACE,yBAAA;EACA,kBAAA;EACA,gBAAA;AADJ;AAIE;EACE,YAAA;EACA,gCAAA;EACA,gCAAA;AAFJ;AAII;EACE,yBAAA;AAFN;AAII;EACE,yBAAA;AAFN","sourcesContent":[".progress-bar\n  width: 100%\n  display: flex\n  align-items: center\n\n  &--sm\n    gap: 4px\n\n    .progress-bar__track\n      width: 100%\n      height: 2px\n      border-radius: 4px\n\n\n  &--md\n    gap: 8px\n\n    .progress-bar__track\n      flex: 0.25\n      height: 8px\n      border-radius: 6px\n\n\n  &__track\n    background-color: #D9D9D9\n    border-radius: 6px\n    overflow: hidden\n\n\n  &__progress\n    height: 100%\n    background-color: var(--primary)\n    transition: all 0.3s ease-in-out\n\n    &.progress-bar--red\n      background-color: #C00F0C\n\n    &.progress-bar--yellow\n      background-color: #efab2b\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
