import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../index";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

interface CreateRequisitesData {
    bank: string;
    accountNumber: string;
    mfo: string;
    currency: string;
    companyId: string;
}

const createRequisites = async (data: CreateRequisitesData): Promise<any> => {
    const response = await axiosInstance.post('/requisites', data);
    return response.data;
};

export const useCreateRequisites = () => {
    const {t} = useTranslation();
    return useMutation<any, Error, CreateRequisitesData>({
        mutationFn: createRequisites,
        onError: (error: any) => {
            toast.error(t(error?.message || 'Error'));
        },

    });
};