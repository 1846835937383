import {PenIcon, PlusIcon} from "../../../utils/icons";
import PageInfo from "../../../components/dashboard/PageInfo";
import FlexWrapper from "../../../components/common/FlexWrapper";
import ContentWrapper from "../../../components/common/ContentWrapper";
import Button from "../../../components/common/Button";
import DataView from "../../../components/common/DataView";
import './styles.sass'
import FileView from "../../../components/common/FileView";

const TrailersView = () => {
  return (
    <div className='page-trucks-view'>
      <PageInfo
          backButton
          title='Volvo1234 324'
          url='/dashboard/companies/view/trailers' />

        <div className="page-content">
          <ContentWrapper>
            <div className="section-title">
              Tirkama  ma'lumotlari

              <Button variant='clear'>
                <PenIcon />
              </Button>
            </div>

            <FlexWrapper>
              <DataView style={{ flex: 1 }} label='Nomi' value='Volvo1234 324' />
              <DataView style={{ flex: 1 }} label='Tirkama modeli' value='Volvo' />
              <DataView style={{ flex: 1 }} label='Ishlab chiqarilgan yili' value='2010' />
            </FlexWrapper>

            <FlexWrapper className='wrapper-grid'>
              <DataView style={{ flex: 1 }} label='Texnik passport seriyasi va raqami' value='AF 234 23 53' />
              <DataView style={{ flex: 1 }} label='Raqami' value='01 B 000 DA' />
              <DataView style={{ flex: 1 }} label='Tirkama  turi' value='Sovutgichli' />
            </FlexWrapper>
          </ContentWrapper>

          <ContentWrapper style={{marginTop: 24}}>
            <div className="section-title">
              Muvofiqliklar

              <Button variant='clear'>
                <PenIcon />
              </Button>
            </div>

            <FlexWrapper className='wrapper-grid'>
              <DataView style={{ flex: 1 }} label='Yo’naltirilganlik statusi' value="Aktiv" />
            </FlexWrapper>

            <FlexWrapper>
              <DataView style={{ flex: 1 }} label='Yo’naltirilgan yuk mashinasi' value='Unit 1234' />
              <DataView style={{ flex: 1 }} label='Yo’naltirilgan haydovchi' value='Abrorbek Mirzayev' />
              <DataView style={{ flex: 1 }} label='Yo’naltirilgan haydovchi manzili' value='Toshkent, O’zbekiston' />
            </FlexWrapper>

            <FlexWrapper className='wrapper-grid'>
              <DataView style={{ flex: 1 }} label='Eslatmalar' value="-" />
            </FlexWrapper>
          </ContentWrapper>

          <ContentWrapper style={{marginTop: 24}}>
            <div className="section-title">
              Dokumentlar
              <Button variant='clear'>
                <PenIcon />
              </Button>
            </div>

            <FlexWrapper>
              <FileView fileName='Tex Passport.jpg' label='Tirkama texnik passporti' />
              <FileView fileName='Tex Passport.jpg' label="Tirkama sug'urtasi passporti" />
              <FileView fileName='Tex Passport.jpg' label='Tirkama ishonchnomasi' />
            </FlexWrapper>

            <Button
              variant='underline'
              className='create-btn'
              iconLeft={<PlusIcon />}
              onClick={() => {}}
            >
              Yana qo'shish
            </Button>
          </ContentWrapper>
        </div>

    </div>
  )
}

export default TrailersView
