import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../../index";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import logo from "../../../components/common/Logo";

interface CreateCompanyData {
    name: string;
    inn: string;
    founded: string;
    ownerId: any;
}

const createCompany = async (data: CreateCompanyData): Promise<any> => {
    const response = await axiosInstance.post('/companies', data);
    return response.data;
};

export const useCreateCompany = () => {
    const { t } = useTranslation();

    return useMutation<any, Error, CreateCompanyData>({
        mutationFn: createCompany,
        onError: (error: any) => {
            if (error.response && error.response.data && error.response.data.message) {
                // Обработка ошибок, возвращенных сервером
                const messages: string[] = error.response.data.message.message;

                // Показать все ошибки с переводом
                messages.forEach((message) => {
                    console.log(message + "sdas");
                    toast.error(t(message)); // Используем `t` для перевода сообщения
                });
            } else {
                // Общая ошибка, если нет подробностей от сервера
                toast.error(t("An unexpected error occurred. Please try again."));
            }
        },
    });
};