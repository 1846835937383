import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import AppRoutes from './routes'
import './config/i18n/i18n'
import './styles/global.sass'
import {QueryClientProvider} from "@tanstack/react-query";
import queryClient from "./config/react-query/queryClient";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<RouterProvider router={AppRoutes} />
			<ToastContainer
				icon={false}
				position="top-right" // Расположение (top-right, top-center, bottom-left и т.д.)
				autoClose={3000}     // Автоматическое закрытие (в мс)
				hideProgressBar={false} // Скрыть прогресс-бар
				newestOnTop={false}  // Новые уведомления сверху
				closeOnClick         // Закрытие при клике
				rtl={false}          // Для языков с правосторонним письмом
				pauseOnFocusLoss     // Пауза при потере фокуса
				draggable            // Возможность перетаскивания
				pauseOnHover         // Пауза при наведении мыши
				theme="light"        // Темы: light, dark или colored
			/>
		</QueryClientProvider>
	</React.StrictMode>
)
