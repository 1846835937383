// api/auth/mutation/login.tsx

import { useCustomMutation } from "../../hooks/useCustomMutation";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export interface LoginData {
    phone: string;
    password: string;
    accessToken?: string; // accessToken может быть неопределён
}

export const useLogin = () => {
    const navigate = useNavigate();

    return useCustomMutation<LoginData, LoginData>('/auth/sign-in', {
        onSuccess: (data) => {
            console.log('Login response', data);

            if (data?.accessToken) { // Проверяем, есть ли accessToken
                Cookies.set('accessToken', data.accessToken, { expires: 30, secure: true, sameSite: 'Strict' });
            } else {
                console.log('No token available yet, waiting for OTP verification');
            }
        },

        onError: (error) => {
            console.error('Login failed', error);
        },
    });
};