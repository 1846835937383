import { CSSProperties, FC, forwardRef, InputHTMLAttributes, useState } from 'react';
import classNames from 'classnames';
import './styles.sass';
import { ClosedEye, OpenEye } from "../../../utils/icons";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    error?: any;
    containerStyle?: CSSProperties;
    onPasswordToggle?: (visible: boolean) => void; // Колбэк для передачи состояния
    isPasswordVisible?: boolean; // Управление видимостью извне
}

const Input: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
    ({ type, label, required, error, containerStyle, isPasswordVisible, onPasswordToggle, ...props }, ref) => {
        const [localPasswordVisible, setLocalPasswordVisible] = useState(false);

        const togglePasswordVisibility = () => {
            const newVisibility = !localPasswordVisible;
            setLocalPasswordVisible(newVisibility);
            onPasswordToggle?.(newVisibility); // Вызываем колбэк, если он передан
        };

        const passwordVisible = isPasswordVisible !== undefined ? isPasswordVisible : localPasswordVisible;

        return (
            <div className="input-container" style={containerStyle}>
                <label className="input-label">
                    {label && (
                        <p className="input-label__text">
                            {label}
                            {required && <span>*</span>}
                        </p>
                    )}

                    <div className="input-wrapper">
                        <input
                            ref={ref}
                            className={classNames('input', {
                                'input--password': type === 'password',
                                'input--error': error,
                            })}
                            type={type === 'password' && passwordVisible ? 'text' : type}
                            {...props}
                        />

                        {type === 'password' && (
                            <span className="toggle-password-icon" onClick={togglePasswordVisibility}>
                {passwordVisible ? <ClosedEye /> : <OpenEye />}
              </span>
                        )}
                    </div>
                </label>

                {error && <div className="error-message">{error}</div>}
            </div>
        );
    }
);

export default Input;