import { useMutation } from '@tanstack/react-query';
import axiosInstance from "../../index";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

export interface FeedbackData {
  name: string;
  phone: string;
  email: string;
  message?: string;
}

export const useSendFeedback = () => {
  const {t} = useTranslation();

  return useMutation({
    mutationFn: async (data: FeedbackData): Promise<any> => {
      const response = await axiosInstance.post('/contact/send', data);
      return response.data;
    },
    onSuccess(response: any) {
      toast.success(t(response.message));
    },
    onError(error: any) {
      toast.error(t(error.response.data?.message?.message[0]));
    }
  });
};
