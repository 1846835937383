import { ChangeEvent, FormEvent, useState } from 'react';
import Input from '../../../components/common/Input';
import Button from '../../../components/common/Button';
import ProgressBar from "../../../components/common/ProgressBar";
import { restorePassword } from "../../../api/auth/queries/restorePassword";
import { useNavigate } from "react-router-dom";

const Restore = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [formError, setFormError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(e.target.value);
    };

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (phoneNumber) {
            try {
                setLoading(true);
                await restorePassword(phoneNumber);
                navigate('/auth/verification', { state: { phoneNumber } }); // передаем номер телефона
            } catch (error) {
                console.error('Password reset failed:', error);
            } finally {
                setLoading(false);
            }
        } else {
            setFormError('Enter the phone number');
        }
    };

    return (
        <div className='page-restore'>
            <h1 className='page-restore__title'>Parolingizni unutdingizmi?</h1>
            <ProgressBar stepsCount={3} currentStep={1} style={{ marginTop: 24 }} />
            <p className='page-restore__descr'>Iltimos, qabul qilish uchun telefon raqamingizni kiriting.</p>
            <form onSubmit={onSubmit}>
                <Input
                    label='Telefon raqam'
                    placeholder="+998 (__) ___ __ __"
                    value={phoneNumber}
                    onChange={onChangeHandler}
                    error={!!formError}
                />
                {formError && <p className='form-error'>{formError}</p>}
                <Button fullWidth size='lg' type='submit' disabled={loading} style={{ marginTop: 40 }}>
                    {loading ? 'Yuborilmoqda...' : 'Yuborish'}
                </Button>
            </form>
        </div>
    );
};

export default Restore;