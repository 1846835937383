import { createBrowserRouter, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import authorizationRoutes from './auth';
import AuthorizationLayout from '../layouts/AuthorizationLayout';
import DashboardLayout from '../layouts/DashboardLayout';
import dashboardRoutes from './dashboard';
import NotFound from '../pages/NotFound/NotFound';
import ComingSoon from "../pages/ComingSoon";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
	const token = Cookies.get('accessToken');
	if (token) {
		return <Navigate to="/dashboard" />;
	}
	return children;
};

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
	const token = Cookies.get('accessToken');
	if (!token) {
		return <Navigate to="/auth" />;
	}
	return children;
};

const AppRoutes = createBrowserRouter([
	// Authorization Layout and Routes
	{
		path: '/auth',
		element: (
			<ProtectedRoute>
				<AuthorizationLayout />
			</ProtectedRoute>
		),
		children: authorizationRoutes,
	},
	// Dashboard Layout and Routes
	{
		path: '/dashboard',
		element: (
			<PrivateRoute>
				<DashboardLayout />
			</PrivateRoute>
		),
		children: dashboardRoutes,
	},
	{
		path: '/',
		element: <ComingSoon />,
	},
	{
		path: '*',
		element: <NotFound />,
	},
]);

export default AppRoutes;