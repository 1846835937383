// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-verification {
  max-width: 464px;
  width: 100%;
  padding: 48px 0;
}
.page-verification__title {
  margin: 0;
  color: #172B4D;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.6;
}
.page-verification__descr {
  margin: 0;
  margin-top: 32px;
  color: #7E7E7E;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
}
.page-verification__descr .phone {
  color: var(--text-default);
  font-size: 18px;
  font-weight: 600;
}
.page-verification form {
  margin-top: 32px;
}
.page-verification .form-error {
  margin: 0;
  margin-top: 4px;
  color: var(--error-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
}
.page-verification .timer {
  color: #7E7E7E;
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.2px;
}
.page-verification .timer__time {
  color: #3366FF;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/pages/Auth/Verification/styles.sass"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;EACA,eAAA;AACF;AACE;EACE,SAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AACJ;AACE;EACE,SAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AACJ;AACI;EACE,0BAAA;EACA,eAAA;EACA,gBAAA;AACN;AACE;EACE,gBAAA;AACJ;AACE;EACE,SAAA;EACA,eAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AACJ;AACE;EACE,cAAA;EACA,eAAA;EACA,cAAA;EACA,gBAAA;EACA,qBAAA;AACJ;AACI;EACE,cAAA;EACA,gBAAA;AACN","sourcesContent":[".page-verification\n  max-width: 464px\n  width: 100%\n  padding: 48px 0\n\n  &__title\n    margin: 0\n    color: #172B4D\n    font-size: 28px\n    font-weight: 700\n    line-height: 1.6\n\n  &__descr\n    margin: 0\n    margin-top: 32px\n    color: #7E7E7E\n    font-size: 18px\n    font-weight: 400\n    line-height: 1.5\n\n    .phone\n      color: var(--text-default)\n      font-size: 18px\n      font-weight: 600\n\n  form\n    margin-top: 32px\n\n  .form-error\n    margin: 0\n    margin-top: 4px\n    color: var(--error-color)\n    font-size: 14px\n    font-weight: 400\n    line-height: 1.6\n  \n  .timer\n    color: #7E7E7E\n    font-size: 16px\n    line-height: 1\n    font-weight: 400\n    letter-spacing: .2px\n\n    &__time\n      color: #3366FF\n      font-weight: 500"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
