// api/employees/useCreateEmployee.ts
import { useMutation } from "@tanstack/react-query";

import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import axiosInstance from "../../../index";

// Define the employee data type
interface EmployeeData {
    userId: string;
    companyId: string | undefined;
    hiredDate: string;
}

// Create a function to post employee data to the server
const createEmployeeMutationFn = (employeeData: EmployeeData) =>
    axiosInstance.post('/company-employee', employeeData);

export const useCreateEmployee = () => {
    const { t } = useTranslation();

    return useMutation<any, Error, EmployeeData>({
        mutationFn: createEmployeeMutationFn,
        onError: (error: any) => {
            toast.error(t(error?.message || 'Error'));
        },

    });
};