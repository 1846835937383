// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.textarea {
  padding: 13px 16px;
  height: 240px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  resize: none;
  outline: none;
  border-radius: 4px;
  background: #FFF;
  transition: background 0.2s ease-in, border-color 0.2s ease-in;
  border: 1.5px solid #CED4DA;
}
.textarea::placeholder {
  color: #ADB5BD;
}
.textarea:focus {
  border-color: var(--primary);
  background: #FFFFFF !important;
}
.textarea:hover {
  background: #F8F9FA;
}
.textarea--error {
  border-color: var(--error-color);
}
.textarea--error:focus {
  outline: 1px solid var(--error-color);
  border-color: var(--error-color);
}`, "",{"version":3,"sources":["webpack://./src/components/common/TextArea/styles.sass"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;EACA,8DAAA;EACA,2BAAA;AACF;AAAE;EACE,cAAA;AAEJ;AAAE;EACE,4BAAA;EACA,8BAAA;AAEJ;AAAE;EACE,mBAAA;AAEJ;AAAE;EACE,gCAAA;AAEJ;AAAI;EACE,qCAAA;EACA,gCAAA;AAEN","sourcesContent":[".textarea\n  padding: 13px 16px\n  height: 240px\n  width: 100%\n  font-size: 16px\n  font-weight: 400\n  line-height: 22.4px\n  resize: none\n  outline: none\n  border-radius: 4px\n  background: #FFF\n  transition: background .2s ease-in, border-color .2s ease-in\n  border: 1.5px solid #CED4DA\n  &::placeholder\n    color: #ADB5BD\n\n  &:focus\n    border-color: var(--primary)\n    background: #FFFFFF !important\n\n  &:hover\n    background: #F8F9FA\n\n  &--error\n    border-color: var(--error-color)\n\n    &:focus\n      outline: 1px solid var(--error-color)\n      border-color: var(--error-color)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
