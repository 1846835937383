// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout-dashboard {
  min-height: 100vh;
  margin: auto;
  background: #F4F7F9;
  display: flex;
  width: 100%;
}
.layout-dashboard .container {
  display: flex;
  flex-grow: 1;
  height: 100%;
}
.layout-dashboard__wrapper {
  flex: 1 1;
  min-height: 100vh;
  transition: margin-left 0.3s ease-in-out;
  margin-left: 248px;
}
.layout-dashboard.collapsed .layout-dashboard__wrapper {
  margin-left: 80px;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background: #001E43;
  width: 248px;
  transition: width 0.3s ease-in-out;
}
.sidebar.collapsed {
  width: 80px;
}
.sidebar .toggle-button {
  position: absolute;
  top: 150px;
  right: -20px;
  background: none;
  border: none;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/layouts/DashboardLayout/styles.sass"],"names":[],"mappings":"AACE;EACE,iBAAA;EACA,YAAA;EACA,mBAAA;EACA,aAAA;EACA,WAAA;AAAJ;AACI;EACE,aAAA;EACA,YAAA;EACA,YAAA;AACN;AACI;EACE,SAAA;EACA,iBAAA;EACA,wCAAA;EACA,kBAAA;AACN;AACI;EACE,iBAAA;AACN;;AACA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;EACA,kCAAA;AAEF;AAAE;EACE,WAAA;AAEJ;AAAE;EACE,kBAAA;EACA,UAAA;EACA,YAAA;EACA,gBAAA;EACA,YAAA;EACA,eAAA;AAEJ","sourcesContent":[".layout\n  &-dashboard\n    min-height: 100vh\n    margin: auto\n    background: #F4F7F9\n    display: flex\n    width: 100%\n    .container\n      display: flex\n      flex-grow: 1\n      height: 100%\n\n    &__wrapper\n      flex: 1\n      min-height: 100vh\n      transition: margin-left 0.3s ease-in-out\n      margin-left: 248px // стандартная ширина сайдбара\n\n    &.collapsed &__wrapper\n      margin-left: 80px // уменьшенная ширина при свернутом сайдбаре\n\n.sidebar\n  position: fixed\n  top: 0\n  left: 0\n  height: 100vh\n  background: #001E43\n  width: 248px\n  transition: width 0.3s ease-in-out\n\n  &.collapsed\n    width: 80px // ширина для свернутого состояния\n\n  .toggle-button\n    position: absolute\n    top: 150px\n    right: -20px\n    background: none\n    border: none\n    cursor: pointer"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
