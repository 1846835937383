// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-drivers-create {
  padding: 20px 16px 24px 24px;
}

.generate_btn {
  border: 1px solid #CED4DA;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  padding: 13px 13px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Drivers/Create/styles.sass"],"names":[],"mappings":"AAAA;EACE,4BAAA;AACF;;AACA;EACE,yBAAA;EACA,kBAAA;EACA,sBAAA;EACA,eAAA;EACA,kBAAA;AAEF","sourcesContent":[".page-drivers-create\n  padding: 20px 16px 24px 24px\n  //min-height: calc(100% - 73px)\n.generate_btn\n  border: 1px solid #CED4DA\n  border-radius: 5px\n  background-color: #fff\n  cursor: pointer\n  padding: 13px 13px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
