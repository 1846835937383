// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container {
  width: 100%;
}
.input-container .error-message {
  margin-top: 4px;
  color: #FA5252;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
}

.input-label {
  display: flex;
  flex-direction: column;
}
.input-label__text {
  margin: 0;
  margin-bottom: 4px;
  color: #2F3437;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
}
.input-label__text span {
  color: #FA5252;
}

.input-wrapper {
  position: relative;
}

.input {
  padding: 10.5px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  border-radius: 4px;
  border: 1.5px solid var(--border-color);
  background: #FFF;
  transition: background 0.2s ease-in, border-color 0.2s ease-in;
  outline: none;
}
.input::placeholder {
  color: #ADB5BD;
}
.input:focus {
  border-color: var(--primary);
  background: #FFFFFF !important;
}
.input:hover {
  background: #F8F9FA;
}
.input--password {
  padding-right: 44px;
}
.input--error {
  border-color: var(--error-color);
}
.input--error:focus {
  outline: 1px solid var(--error-color);
  border-color: var(--error-color);
}

.toggle-password-icon {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}`, "",{"version":3,"sources":["webpack://./src/components/common/Input/styles.sass"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,eAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AACJ;;AACA;EACE,aAAA;EACA,sBAAA;AAEF;AAAE;EACE,SAAA;EACA,kBAAA;EAEA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AACJ;AAAI;EACE,cAAA;AAEN;;AACA;EACE,kBAAA;AAEF;;AAAA;EACE,eAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,uCAAA;EACA,gBAAA;EACA,8DAAA;EACA,aAAA;AAGF;AAFE;EACE,cAAA;AAIJ;AAFE;EACE,4BAAA;EACA,8BAAA;AAIJ;AAFE;EACE,mBAAA;AAIJ;AAFE;EACE,mBAAA;AAIJ;AAFE;EACE,gCAAA;AAIJ;AAFI;EACE,qCAAA;EACA,gCAAA;AAIN;;AADA;EACE,kBAAA;EACA,QAAA;EACA,WAAA;EACA,2BAAA;AAIF","sourcesContent":[".input-container\n  width: 100%\n\n  .error-message\n    margin-top: 4px\n    color: #FA5252\n    font-size: 12px\n    font-weight: 400\n    line-height: 16.8px\n\n.input-label\n  display: flex\n  flex-direction: column\n\n  &__text\n    margin: 0\n    margin-bottom: 4px\n\n    color: #2F3437\n    font-size: 14px\n    font-weight: 500\n    line-height: 1.4\n    span\n      color: #FA5252\n\n\n.input-wrapper\n  position: relative\n\n.input\n  padding: 10.5px\n  width: 100%\n  font-size: 16px\n  font-weight: 400\n  line-height: 1.6\n  border-radius: 4px\n  border: 1.5px solid var(--border-color)\n  background: #FFF\n  transition: background .2s ease-in, border-color .2s ease-in\n  outline: none\n  &::placeholder\n    color: #ADB5BD\n\n  &:focus\n    border-color: var(--primary)\n    background: #FFFFFF !important\n\n  &:hover\n    background: #F8F9FA\n\n  &--password\n    padding-right: 44px\n\n  &--error\n    border-color: var(--error-color)\n\n    &:focus\n      outline: 1px solid var(--error-color)\n      border-color: var(--error-color)\n\n\n.toggle-password-icon\n  position: absolute\n  top: 50%\n  right: 12px\n  transform: translateY(-50%)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
