import {CSSProperties, FC, forwardRef, InputHTMLAttributes, useState} from 'react';
import classNames from 'classnames';
import './styles.sass';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: any;
  containerStyle?: CSSProperties;
}

const Input: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  ({type, label, required, error, containerStyle, ...props}, ref) => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
      setPasswordVisible(!passwordVisible);
    }

    return (
      <div className='input-container' style={containerStyle}>
        <label className='input-label'>
          {label && (
            <p className='input-label__text'>
              {label}
              {required && (
                <span>*</span>
              )}
            </p>
          )}

          <div className='input-wrapper'>
            <input
              ref={ref}
              className={classNames('input', { 'input--password': type === 'password', 'input--error': error })}
              type={type === 'password' && passwordVisible ? 'text' : type}
              {...props}
            />

            {type === 'password' && (
              <span className="toggle-password-icon" onClick={togglePasswordVisibility}>
                {passwordVisible ? '🙈' : '👁️'}
              </span>
            )}
          </div>
        </label>

         {error && <div className="error-message">{error}</div>}
      </div>
    );
  }
)

export default Input;
