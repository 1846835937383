import { FC, HtmlHTMLAttributes } from 'react';
import './styles.sass';
import classNames from 'classnames';

interface ProgressBarProps extends HtmlHTMLAttributes<HTMLDivElement> {
  size?: 'sm' | 'md';
  stepsCount?: number;
  currentStep?: number;
  coloredProgress?: boolean;
}

const ProgressBar: FC<ProgressBarProps> = ({
  size = 'md',
  stepsCount = 2,
  currentStep = 1,
  className,
  style,
  coloredProgress
}) => {
  const stepsArray = Array.from({ length: stepsCount }, (_, index) => index + 1);
  const classes = classNames(
    'progress-bar',
    `progress-bar--${size}`,
    className
  )

  return (
    <div className={classes} style={style}>
      {stepsArray.map((value) => (
          <div className='progress-bar__track' key={value}>
            <div
              className={classNames('progress-bar__progress', {
                'progress-bar--red': coloredProgress && currentStep * 100 / stepsCount <= 30,
                'progress-bar--yellow': coloredProgress && currentStep * 100 / stepsCount > 30 && currentStep * 100 / stepsCount <= 70,
              })}
              style={{ width: `${currentStep >= value ? '100%' : 0}` }}
            />
        </div>
      ))}
    </div>
  );
};

export default ProgressBar;
