import React, { forwardRef, TextareaHTMLAttributes } from "react";
import classNames from 'classnames';
import '../Input/styles.sass';
import './styles.sass';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  className?: string;
  error?: string;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(({
   label,
   name,
   required = false,
   placeholder = '',
   className,
   error,
   ...otherProps
 }: TextAreaProps, ref) => {
  return (
    <div className="input-container">
      <label className="input-label">
        {label && (
          <p className="input-label__text">
            {label}
            {required && <span>*</span>}
          </p>
        )}

        <textarea
          {...otherProps}
          className={classNames('textarea', className, {'textarea--error': error})}
          name={name}
          placeholder={placeholder}
          ref={ref} // Добавляем реф сюда
        />
      </label>

      {error && <div className="error-message">{error}</div>}
    </div>
  );
});

export default TextArea;
