import LogisticianIcon from '../assets/global/logist.svg'
import CargoOwnerIcon from '../assets/global/cargo-owner.svg'
import TruckIcon from '../assets/global/truck-delivery.svg'
import { ROLES } from '../types/roles.types'
import cargoIcon from '../assets/global/map-icons/cargo.svg';
import driverIcon from '../assets/global/map-icons/driver.svg';
import truckIcon from '../assets/global/map-icons/truck.svg';
import {DriversRow} from "../types/table.types";

export { cargoIcon, driverIcon, truckIcon };

export const RoleCards: {title: string, iconUrl: string, role: ROLES}[] = [
  {
    iconUrl: LogisticianIcon,
    title: 'Logist',
    role: ROLES.LOGISTICIAN
  },
  {
    iconUrl: TruckIcon,
    title: 'Yuk tashuvchi',
    role: ROLES.TRUCK_DELIVERY
  },
  {
    iconUrl: CargoOwnerIcon,
    title: 'Yuk egasi',
    role: ROLES.CARGO_OWNER
  },
]

export const TransportStatus = [
  {
    AVAILABLE : "AVAILABLE",
    IN_TRANSIT : "IN_TRANSIT",
    LOADING : "LOADING",
    UNLOADING : "UNLOADING",
    MAINTENANCE : "MAINTENANCE",
    REPAIR : "REPAIR",
    INSPECTION : "INSPECTION",
    RESERVED : "RESERVED",
    OUT_OF_SERVICE : "OUT_OF_SERVICE",
    BREAKDOWN : "BREAKDOWN",
    PARKING : "PARKING",
    FUELING : "FUELING",
    REST : "REST",
    RETURNING : "RETURNING",
    WAITING : "WAITING",
    DELAYED : "DELAYED",
    INACTIVE : "INACTIVE",
    SUSPENDED : "SUSPENDED",
  }

]

export const TrailersStatus = [
  {
    label: "AVAILABLE",
    value: "AVAILABLE"
  },
  {
    label: "IN_TRANSIT",
    value: "IN_TRANSIT"
  },
  {
    label: "LOADING",
    value: "LOADING"
  },
  {
    label: "UNLOADING",
    value: "UNLOADING"
  },
  {
    label: "MAINTENANCE",
    value: "MAINTENANCE"
  },
  {
    label: "REPAIR",
    value: "REPAIR"
  },
  {
    label: "INSPECTION",
    value: "INSPECTION"
  },
  {
    label: "RESERVED",
    value: "RESERVED"
  },
  {
    label: "OUT_OF_SERVICE",
    value: "OUT_OF_SERVICE"
  },
  {
    label: "BREAKDOWN",
    value: "BREAKDOWN"
  },
  {
    label: "PARKING",
    value: "PARKING"
  },
  {
    label: "FUELING",
    value: "FUELING"
  },
  {
    label: "REST",
    value: "REST"
  },
  {
    label: "RETURNING",
    value: "RETURNING"
  },
  {
    label: "WAITING",
    value: "WAITING"
  },
  {
    label: "DELAYED",
    value: "DELAYED"
  },
  {
    label: "INACTIVE",
    value: "INACTIVE"
  },
  {
    label: "SUSPENDED",
    value: "SUSPENDED"
  }
];

export const CargoAmounts = [
  '1-3',
  '4-5',
  '6-10',
  '11-20',
  '21-50',
  '50 +'
]

export const CargoAmounts2 = [
  '1-5',
  '6-10',
  '11-20',
  '21-50',
  '50 +'
]

export const companiesTableData = [
  {
    name: 'OOO UMID AVTO LOGISTICS',
    director: 'ABDUKHALIM KURBONOV',
    tin: '310 492 143',
    driversCount: 52,
    transportsCount: 35,
    createdYear: 1998
  },
  {
    name: 'OOO COMFORT LOGISTIC 70',
    director: 'ALISHER ALTIEV',
    tin: '306 489 220',
    driversCount: 47,
    transportsCount: 30,
    createdYear: 2001
  },
  {
    name: 'OOO UMID AVTO LOGISTIC',
    director: 'AMIN DUMAEV',
    tin: '311 654 789',
    driversCount: 55,
    transportsCount: 38,
    createdYear: 2002
  },
  {
    name: 'OOO ZOXID YODGOR LOGISTIC',
    director: 'AVAZJON AKHAJIDONOV',
    tin: '310 874 231',
    driversCount: 49,
    transportsCount: 42,
    createdYear: 1997
  },
  {
    name: 'XK ORZU SAVDO SERVIS',
    director: 'BOBOMUROD SHUKUROV',
    tin: '311 498 765',
    driversCount: 53,
    transportsCount: 37,
    createdYear: 1999
  },
  {
    name: 'OOO ZARI LAGIST',
    director: 'BOBOMUROT KHAMROEV',
    tin: '311 783 912',
    driversCount: 45,
    transportsCount: 31,
    createdYear: 2000
  },
  {
    name: 'OOO SAM DILSHOD TRANS AVTO',
    director: 'DILSHOD KHASAMOV',
    tin: '306 689 321',
    driversCount: 50,
    transportsCount: 39,
    createdYear: 2003
  },
  {
    name: 'OOO LONG WAY-LOGISTIC',
    director: 'FAHRIDIN NURMATOV',
    tin: '310 874 654',
    driversCount: 48,
    transportsCount: 34,
    createdYear: 1998
  },
  {
    name: 'XK ORZU SAVDO SERVIS',
    director: 'GYOJON NORKUZIEV',
    tin: '306 643 219',
    driversCount: 56,
    transportsCount: 43,
    createdYear: 2001
  },
  {
    name: 'OOO SAYID SAMOSIY TRANS',
    director: 'HOJIAKBAR SAYIDOV',
    tin: '306 458 963',
    driversCount: 44,
    transportsCount: 32,
    createdYear: 2004
  },
  {
    name: 'OOO IMRON TOIR TRANS',
    director: 'IKROMJON KARIMOV',
    tin: '311 784 112',
    driversCount: 53,
    transportsCount: 38,
    createdYear: 1996
  },
  {
    name: 'OOO VARZIK FIRDAWS TRANS',
    director: 'JAMSHID MIRMAKHUMUDOV',
    tin: '310 921 753',
    driversCount: 57,
    transportsCount: 40,
    createdYear: 1997
  },
  {
    name: 'OOO IMRON TOIR TRANS',
    director: 'MUHAMADJON RAHIMOV',
    tin: '306 578 246',
    driversCount: 49,
    transportsCount: 36,
    createdYear: 1998
  },
  {
    name: 'OOO LAZOXATKON TRANS',
    director: 'NAZIRKHON IBROKHIMOV',
    tin: '311 647 128',
    driversCount: 46,
    transportsCount: 33,
    createdYear: 2002
  },
  {
    name: 'OOO IMRON TOIR TRANS',
    director: 'SHARIFJON SHAROPOV',
    tin: '310 712 349',
    driversCount: 54,
    transportsCount: 41,
    createdYear: 1999
  },
  {
    name: 'OOO LAZOXATKON TRANS',
    director: 'SHERZOD VALIYEV',
    tin: '310 456 179',
    driversCount: 50,
    transportsCount: 39,
    createdYear: 1996
  },
  {
    name: 'OOO CHUST AXMEDOV SAVDO SERVIS',
    director: 'TULKINJON FOZILOV',
    tin: '306 894 567',
    driversCount: 47,
    transportsCount: 34,
    createdYear: 2000
  },
  {
    name: 'OOO WAGEN PARK LV',
    director: 'VALERIY VASILEV',
    tin: '311 978 453',
    driversCount: 58,
    transportsCount: 42,
    createdYear: 1997
  },
  {
    name: 'OOO LAZOXATKON TRANS',
    director: 'VALIYEV SHERZOD',
    tin: '311 748 921',
    driversCount: 51,
    transportsCount: 37,
    createdYear: 2001
  },
  {
    name: 'OOO WAGEN PARK LV',
    director: 'VITALIY RAZMAKHNIN',
    tin: '310 615 842',
    driversCount: 55,
    transportsCount: 41,
    createdYear: 2003
  },
];

export const companiesDocs = [
  {
    id: 0,
    header: 'Guvohnoma',
    body: [
      'Rahbar passporti oldi.jpg',
    ],
  },
  {
    id: 1,
    header: 'Rahbar passporti',
    body: [
      'Rahbar passporti oldi.jpg',
      'Rahbar passporti orqasi.jpg'
    ],
  },
  {
    id: 2,
    header: 'Ijara shartnomasi',
    body: [
      'Rahbar passporti oldi.jpg'
    ],
  }
]

export const createDocSelect = [
  {
    label: 'Guvohnoma',
    value: 'Guvohnoma'
  },
  {
    label: 'Rahbar passporti',
    value: 'Rahbar passporti'
  },
  {
    label: 'Ijara shartnomasi',
    value: 'Ijara shartnomasi'
  },
  {
    label: 'Kadastr',
    value: 'Kadastr'
  },
  {
    label: 'Litsenziyalar',
    value: 'Litsenziyalar'
  },
  {
    label: 'Boshqa dokumentlar',
    value: 'Boshqa dokumentlar'
  },
  {
    label: 'Haydovchilik guvohnomasi',
    value: 'Haydovchilik guvohnomasi'
  },
]

export const driversRows: DriversRow[] = [
  {
    id: 1,
    driverName: 'Azizbek Abdullayev',
    passportData: 'AD 345 64 23',
    hgId: 'AF 122 21 31',
    carNumber: '85 A 927 GB',
    cargoCount: 120,
    status: 'Aktiv',
  },
  {
    id: 2,
    driverName: 'Bek Mahmudov',
    passportData: 'AD 456 78 90',
    hgId: 'AF 123 43 51',
    carNumber: '01 B 738 DA',
    cargoCount: 342,
    status: 'Aktiv',
  },
  {
    id: 3,
    driverName: 'Dilmurod Akramov',
    passportData: 'AD 567 89 01',
    hgId: 'AF 124 54 32',
    carNumber: '70 C 945 CD',
    cargoCount: 210,
    status: 'Aktiv',
  },
  {
    id: 4,
    driverName: 'Jamshid Nazarov',
    passportData: 'AD 678 90 12',
    hgId: 'AF 125 65 43',
    carNumber: '60 D 562 EF',
    cargoCount: 300,
    status: 'Aktive emas',
  },
  {
    id: 5,
    driverName: 'Nodirbek Tursunov',
    passportData: 'AD 789 01 23',
    hgId: 'AF 126 76 54',
    carNumber: '90 E 674 GH',
    cargoCount: 150,
    status: 'Aktiv',
  },
  {
    id: 6,
    driverName: 'Olimjon Rustamov',
    passportData: 'AD 890 12 34',
    hgId: 'AF 127 87 65',
    carNumber: '75 F 781 IJ',
    cargoCount: 400,
    status: 'Aktiv',
  },
  {
    id: 7,
    driverName: 'Parviz Yuldashev',
    passportData: 'AD 901 23 45',
    hgId: 'AF 128 98 76',
    carNumber: '80 G 890 JK',
    cargoCount: 275,
    status: 'Aktiv',
  },
  {
    id: 8,
    driverName: 'Rustamjon Shodiev',
    passportData: 'AD 012 34 56',
    hgId: 'AF 129 09 87',
    carNumber: '10 H 901 LM',
    cargoCount: 310,
    status: 'Aktive emas',
  },
  {
    id: 9,
    driverName: 'Sanjarbek Kamilov',
    passportData: 'AD 123 45 67',
    hgId: 'AF 130 10 98',
    carNumber: '95 I 012 NO',
    cargoCount: 180,
    status: 'Aktiv',
  },
];
