import { ReactNode, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageInfo from '../../../../../components/dashboard/PageInfo';
import FlexWrapper from '../../../../../components/common/FlexWrapper';
import Tag from '../../../../../components/common/Tag';
import DataTable from '../../../../../components/dashboard/DataTable';
import Button from '../../../../../components/common/Button';
import { PlusIcon, ChevronDown, TrashIcon, PenIcon } from "../../../../../utils/icons";
import { useTranslation } from 'react-i18next';
import { useCompanyDrivers } from '../../../../../api/hooks/company/employe/useCompanyDrivers';  // Импорт хука
import { Column } from '../../../../../types/table.types';
import './styles.sass';

const CompanyDrivers = ({ navigationSlot }: { navigationSlot: ReactNode }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { companyID } = useParams();
  const { data: drivers, isLoading, isError, refetch } = useCompanyDrivers(companyID!);

  const columns: Column[] = [
    {
      id: 'driver-name',
      flex: 2,
      headerName: t('driver_name'),
      renderCell(row: any) {
        return (
            <div
                style={{ cursor: 'pointer' , color: '#3B7CCC' , textDecoration : 'underline' }}
                onClick={() => navigate(`/dashboard/drivers/${companyID}/view/${row.userId}`, {
                  state: {
                    name: row.user?.name ,
                    status: row.status,
                  }
                })}
            >
              {row.user?.name || row.driverName}
            </div>
        );
      },
    },
    {
      id: 'passport-number',
      flex: 1,
      headerName: t('passport_data'),
      renderCell(row: any) {
        return <div>{row.user.serial}</div>;
      },
    },
    {
      id: 'hg-id',
      flex: 1,
      headerName: t('hg_id'),
      renderCell(row: any) {
        return <div>{row.hgId}</div>;
      },
    },
    {
      id: 'car-number',
      flex: 1,
      headerName: t('car_number'),
      renderCell(row: any) {
        return <div>{row.carNumber}</div>;
      },
    },
    {
      id: 'cargo-number',
      flex: 1,
      headerName: t('cargo_count'),
      renderCell(row: any) {
        return <div>{row.cargoCount}</div>;
      },
    },
    {
      id: 'status',
      flex: 1,
      headerName: t('status'),
      renderCell(row: any) {
        return <div>{row.status === 'Aktiv'
            ? <Tag text={row.status} color='primary' />
            : <Tag text={row.status} color='error' />
        }</div>;
      },
    },
    {
      id: 'action',
      headerName: t('actions'),
      flex: 0.5,
      renderCell(row: any) {
        return (
            <FlexWrapper>
              <Button variant='clear' onClick={() => navigate(`/dashboard/drivers/${row.user.id}/edit`)}>
                <PenIcon />
              </Button>
              <Button variant='clear'>
                <TrashIcon />
              </Button>
            </FlexWrapper>
        );
      },
    },
  ];
  useEffect(() => {
    if (companyID) {
      refetch();
    }
  }, [companyID, refetch]);
  if (isLoading) {
    return <div className='page-companies-view'>Loading...</div>;
  }

  if (isError) {
    return <div>Error loading drivers.</div>;
  }

  return (
      <div className='page-companies-view'>
        <PageInfo
            title={t('drivers')}
            backButton
            url='/dashboard/companies'
            buttonsSlot={
              <FlexWrapper>
                <Button variant='secondary' iconRight={<ChevronDown width={16} height={16} />}>
                  {t('bulk_update')}
                </Button>
                <Button
                    iconLeft={<PlusIcon width={16} height={16} stroke='#fff' />}
                    onClick={() => navigate(`/dashboard/drivers/${companyID}/create`)}
                >
                  {t('add_driver')}
                </Button>
              </FlexWrapper>
            }
        />

        <FlexWrapper>
          {navigationSlot}

          <div className="page-content">
            <DataTable columns={columns} rows={drivers || []} filterTitle={t('driver_name')} />
          </div>
        </FlexWrapper>
      </div>
  );
};

export default CompanyDrivers;