import { useMutation } from "@tanstack/react-query";
import axiosInstance from "../index";
import {getUserIdFromToken} from "../../utils/authUtils";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

// Define the type for updating only the roles field
interface UpdateUserData {
    roles: string[];
}

// Function to send a PATCH request for updating roles only
const updateUser = async (data: UpdateUserData): Promise<any> => {
    const userId = getUserIdFromToken(); // Retrieve userId from token
    const response = await axiosInstance.patch(`/user/${userId}`, { roles: data.roles });
    return response.data;
};

// Hook to use the request
export const useUpdateUser = () => {

    const {t} = useTranslation();

    return useMutation<any, Error, UpdateUserData>({
        mutationFn: updateUser,
        onError: (error: any) => {
            toast.error(t(error?.message || 'Error'));
            console.log("Error:", error);
        }
    });
};